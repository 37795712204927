import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import keshroot from "../../Assets/Projects/keshroot.png";
import zerozero from "../../Assets/Projects/zerozero.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={keshroot}
              isBlog={false}
              title="Keshroot"
              description="Nourish your hair with the power of 13 rich  ingredients Say goodbye to dry, dull hair with Keshroot Hair Oil's powerful formula Your hair care partner for life, Keshroot Hair Oil"
              demoLink="https://www.keshroot.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={zerozero}
              isBlog={false}
              title="ZeroZero Hair Removal Cream"
              description="Zero Zero is a brand that truly believes that women should feel confident and comfortable in their skin. As physical appearance has always opened up a can of insecurities, our mission is to empower and encourage women to embrace themselves."
              demoLink="https://www.zerozeroindia.com/"
            />
          </Col>

          
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;

import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";
// import { ImMobile } from "react-icons/im";
// import { ImMail2 } from "react-icons/im";

function AboutCard() {
  return (<>
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Jignesh Maru </span>
            from <span className="purple"> Bhavnagar, Gujarat, India.</span>
            <br /> I am a final year student pursuing a Bachlor of Engineering (B.E.)
            in Computer Engineering at GEC,Rajkot.
            <br />
            Additionally, I am currently employed as a software developer at
            REVEATION TECH.
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Cricket
            </li>
            <li className="about-activity">
              <ImPointRight /> Watching Movies
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Strive to build things that make a difference!"{" "}
          </p>
          <footer className="blockquote-footer">Jignesh</footer>
        </blockquote>
      </Card.Body>
    </Card>
      {/* <div style={{marginTop:'10%'}}>
      <h3 style={{float:'left'}}>Contact Me....!</h3><br/><br/><br/>
      <p style={{float:'left'}}><ImMobile/>&ensp;+91 7573834222</p><br/><br/>
      <p style={{float:'left'}}><ImMail2/>&ensp;jigneshmaru180902@gmail.com</p>
      </div> */}
      </>
  );
}

export default AboutCard;
